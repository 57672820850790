<template>

    <div class="menu-warp">
        <router-link tag="div" to="/recharge" class="menu">
            <img src="../assets/images/icon/mobile/menu/입금.png" alt="">
            <div>입금</div>
        </router-link>
        <router-link tag="div" to="/exchange" class="menu">
            <img src="../assets/images/icon/mobile/menu/출금.png" alt="">
            <div>출금</div>
        </router-link>
        <router-link tag="div" to="/main" class="menu">
            <img src="../assets/images/icon/mobile/menu/홈.png" alt="">
            <div>홈</div>
        </router-link>
        <router-link tag="div" to="/customer_center" class="menu">
            <img src="../assets/images/icon/mobile/menu/고객센터.png" alt="">
            <div>문의</div>
        </router-link>
        <router-link tag="div" to="/message" class="menu">
            <img src="../assets/images/icon/mobile/menu/쪽지.png" alt="">
            <div>쪽지</div>
        </router-link>
    </div>

</template>

<script>
    export default {
        name: "MobileFootMenus"
    }
</script>

<style scoped>
    .menu-warp{
        position: fixed;
        z-index: 300;
        bottom: 0;
        width: 100%;
        height: 65px;
        background-color: #007390;
        border-top: 1px solid #57c1db;
        display: none;
        align-items: center;
        justify-content: space-evenly;
        flex-wrap: nowrap;

    }
    .menu{
        width: 19.9%;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
      color: floralwhite;
    }
    @media screen and (max-width: 1024px) {
        .menu-warp{
            display: flex!important;
        }
    }
</style>